<template>
  <PatologiesPage
    title="Diverticoli esofagei"
    subtitle1="I diverticoli esofagei o epifrenici sono delle estroflessioni a forma di “sacco” della mucosa dell’esofago. La loro reale prevalenza è probabilmente sottostimata, ma si ritiene che siano comunque rari. Si ritiene che la lo loro insorgenza sia dovuta a delle alterazioni della motilità esofagea (acalasia, spasmo esofageo diffuso, esofago a schiaccianoci, etc..) che attraverso un meccanismo di pulsione inducono una sorta di sfiancamento della mucosa esofagea. Possono essere di dimensioni variabili da uno a molti centimetri e non esiste spesso una correlazione diretta fra le loro dimensioni e la sintomaticità. Non è infrequente osservare pazienti con grossi diverticoli asintomatici o altri con piccoli diverticoli fortemente sintomatici. I disturbi più frequenti sono la disfagia (sensazione di difficoltà al transito del bolo alimentare), il rigurgito salivare o alimentare, l’alitosi, il dolore toracico e non infrequentemente sintomi respiratori da inalazione di cibo o saliva rigurgitati."
    subtitle2="Per un corretto inquadramento diagnostico è opportuna l’esecuzione di una EGDS e di un Rx tubo digerente prime vie per valutare dimensioni e posizione del diverticolo ed in alcuni casi la TAC torace-addome per valutarne l’altezza e i rapporti anatomici. In questi pazienti trova indicazione l’esecuzione anche della manometria esofagea, per identificare l’eventuale alterazione motoria esofagea sottostante."
    subtitle3="Si è visto che pazienti pauci- o asintomatici hanno una scarsa propensione a peggiorare con il passare del tempo, indipendentemente dalle dimensioni del diverticolo, mentre coloro che sono sintomatici tendono a progredire. Questa osservazione permette di effettuare una gestione conservativa di coloro che sono paucisintomatici con farmaci o con misure igienico-dietetiche e con il controllo ambulatoriale nel tempo. La chirurgia è riservata a coloro che invece lamentano sintomi. L’intervento viene condotto per via laparoscopica mini-invasiva e consiste nell’asportazione del diverticolo (diverticulectomia), nell’esecuzione di una miotomia dello sfintere esofageo inferiore per il trattamento dell’alterazione motoria esofagea sottostante e nel confezionamento di una plastica antireflusso, per prevenire l’insorgenza di reflusso post-miotomia. In casi particolari, in cui il diverticolo è particolarmente grande o alto, è necessario eseguire anche una toracoscopia per completare la diverticulectomia. Per questo intervento è previsto che il paziente resti a digiuno per 3-4 giorni, dopodiché se i controlli saranno regolari verrà consentita una dieta morbida per un paio di settimane e successivamente una dieta libera. La degenza prevista è circa 5-6 giorni."
    cta1="Costituisce il primo momento di ricognizione e valutazione della storia clinica, dei sintomi e dello stato di salute. Il medico specialista prende visione degli esami diagnostici effettuati; per questo motivo le consigliamo di portare con sé almeno una TC del torace e dell’addome con mezzo di contrasto ed un’esofagogastroscopia recente, corredata di biopsia ed indagine istologica."
    cta2="Il medico potrebbe richiederle alcuni approfondimenti diagnostici utili alla scelta terapeutica più efficace e personalizzata. Potrebbero completare il quadro strumentale alcuni esami, fra cui lo studio radiologico delle prime vie digestive, la manometria esofagea, la pH-impedenziometria esofagea delle 24 ore e la pH-metria esofagea delle 24 ore."
    cta3="Le opportunità di trattamento possono variare da terapie mediche, mediante la sola assunzione di farmaci, a trattamenti endoscopici, fino ad intervento chirurgico con la preferenza per approcci di chirurgia mini-invasiva. La scelta migliore sarà discussa e condivisa con lei."
    cta4="Al termine della maggior parte dei trattamenti terapeutici proposti si rende utile seguire un piano di controlli diagnostici periodici, concordati con il medico specialista. Questi saranno valutati durante la visita con lo specialista e verranno suggeriti i successivi."
  />
</template>

<script>
import PatologiesPage from "../components/PatologiesPage";

export default {
  name: "Diverticoli",
  components: {
    PatologiesPage,
  },
};
</script>
